.mainDiv5{
    width: 450px;
    overflow: hidden;
    border: 1px solid white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.cardTop5{
    background-color:gold;
}
.cardImgHolder5 img{
    height: 100%;
    width: 100%;
}
.cardImgHolder5{
    width: 450px;
    padding:4px;
}
.classdetailsHeader5 h5{
    text-align: center;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 1.5rem;
}
.cardBottom5{
    padding: 1rem;
}
.cardBottom5 p {
    font-weight: bolder;
}
.CardIcons {
    margin-right: 1rem;
    font-size: x-large;
    font-weight: bolder;
    color: cornflowerblue;
}
.priceDetails5{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
.link5 a{
    color:  rgb(13, 100, 214);
    text-decoration: none;
    font-weight: bold;
}
.price5 h4{
    font-weight: 600;
    font-size: 1.2rem;
}
.notPrice5{
    font-size: 1rem;
    padding: 9px;
    font-weight: bold;
    color: rgb(156, 155, 155);
    text-decoration-thickness: 2px;
}
.buyNowbtn5{
    text-align: center;
}
.takeCourseBtn5{
    margin-top: 7px;
    background-color: rgb(101, 194, 9);
    padding: 0.5rem;
    color: white;
    border-radius: 30px;
    border: 1px solid rgb(101, 194, 9);
    width: 50%;
    margin-top: 1rem;
}
.takeCourseBtn5 i{
    margin-right: 5px;
    font-size: small;
}
.customDivider{
    border: none; 
    border-top: 1px solid #ccc; 
    margin: 0.5rem 0; 
    width: 100%;
}