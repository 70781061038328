/* DocumentUpload page css */

.SectionButtons{
    width: 10rem;
    height: 5rem;
    background-color: #49a9e0;
    font-size: 15px;
    font-weight: 700;
}

.SectionButtonsmaindiv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 2rem;
}

.maindivInstructions{
    border:  2px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 7rem;
    padding: 1rem;
    background-color: #c7c4c463;
}

.maindivInstructions ul{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    gap: 0.5rem;
}