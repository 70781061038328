.mainDiv7{
    width: 400px;
    overflow: hidden;
    border: 1px solid white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.cardTop7{
    background-color:rgb(248, 199, 63);
}
.classdetailsHeader7{
    padding: 0.5rem;
}
.classdetailsHeader7 h5{
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
}
.cardBottom7{
    padding: 1rem;
}
.cardBottom7 p {
    font-weight: bolder;
}
.CardIcons {
    margin-right: 1rem;
    font-size: x-large;
    font-weight: bolder;
    color: cornflowerblue;
}
.buyNowbtn7{
    text-align: center;
}
.takeCourseBtn7{
    margin-top: 7px;
    background-color: rgb(101, 194, 9);
    padding: 0.7rem;
    color: white;
    border-radius: 30px;
    border: 1px solid rgb(101, 194, 9);
    font-weight: bold;
    margin-top: 1rem;
}
.classHeader7{
    text-align: center;
    color: cornflowerblue;
}
.para7{
    font-size: small;
    color: rgb(59, 59, 59);
    margin-bottom: 1rem;
}
.CardIcons {
    margin-right: 1rem;
    font-size: x-large;
    font-weight: bolder;
    color: cornflowerblue;
}
.customDivider{
    border: none; 
    border-top: 1px solid #ccc; 
    margin: 0.5rem 0; 
    width: 100%;
}