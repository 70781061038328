.mainDiv2{
    width: 400px;
    overflow: hidden;
    border: 1px solid skyblue;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
.cardTop2 img{
    width: 100%;
    height: 100%;
}
.cardTop2{
    width: 450px;
}
.cardBottom2{
    background-color: #fff;
    padding: 10px;
}
.classDetail2 h4{
    color: red;
}
.courseDetails2 li{
    padding: 0.6rem;
    list-style: disc;
    margin: 1rem;
}
.courseDetails2 li span{
    font-weight: bold;
}
.priceDetails2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.link2 a{
    color:  rgb(13, 100, 214);
    text-decoration: none;
    font-weight: bold;
    font-size: large;
}
.buyNowbtn2{
    text-align: center;
}
.buyNowbtn2 button{
    border: 1px solid rgb(13, 100, 214);
    padding: 0.5rem;
    color: white;
    background-color: rgb(13, 100, 214);
    border-radius: 5px;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
}
.classDetail2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.classIcon2 {
    font-weight: bolder;
    font-size: x-large;
}
.notPrice2{
    font-size: 1rem;
    padding: 9px;
    font-weight: bold;
    color: rgb(156, 155, 155);
    text-decoration-color: red;
    text-decoration-thickness: 2px;
}