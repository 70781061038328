.otsMainFullWidth{
    /* border: 3px solid red; */
    height: 100vh;
    background: #fff;
}
.terminationPopUp {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.terminationPopUpContent {
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.cancelbtn{
    background: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}
.cancelbtn:hover {
    background: #a71d2a;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
@media(max-width:425px){
    .terminationPopUpContent {
        margin: 3rem;
    }
    
}
@media(max-width:375px){
    .terminationPopUpContent {
        margin: 2rem;
    }
}
@media(max-width:320px){
    .terminationPopUpContent {
        margin: 1.5rem;
    }
}