.mainDiv6{
    width: 400px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    border: 1px solid rgb(199, 197, 197);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.cardTop6{
    gap: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem;
    background: rgb(244,132,57);
    background: linear-gradient(90deg, rgba(244,132,57,1) 0%, rgba(249,217,62,1) 81%, rgba(248,214,61,1) 100%);;
}
.cardimg6 img{
    width: 100%;
    height: 100%;
    border-radius: 40px;  
}
.cardimg6{
    max-width: 80px;
   
}
.cardHead6{
    font-weight: bolder;
    font-size: larger;
}
.schedule6{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.paraP6{
    color: rgb(116, 115, 115);
    font-weight: bold;
}
.paraP6 i{
    margin-right: 5px;
}
.courseDetails6{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:2rem;
    padding: 1rem;
    background: linear-gradient(to right, white 50%, rgb(199, 197, 197) 50%);
    border: 1px solid rgb(199, 197, 197);
    border-width: 1px 0px 0px 0px;
}
.viewDetails6 button{
    border: 1px solid rgb(3, 100, 228);
    color:  rgb(3, 100, 228);
    padding: 1rem;
    background-color: white;
    border-radius: 30px;
    font-weight: 600;
}
.select6 input{
    margin-right: 10px;
}
.details6{
    color: black;
}
.batch6{
    border: 1px solid  rgb(65, 133, 235);
    background-color: rgb(65, 133, 235);
    color: white;
    font-weight: bold;
    border-radius: 1.2rem;
    padding: 7px;
}
.fieldset6{
    border: 1px solid rgba(182, 182, 182, 0.815);
    border-radius: 10px;
    padding: 6px;
    width: fit-content;
    position: relative;
    top: 25px;
    font-weight: 600;
    background-color: white;
    font-size: 1.2rem;
}
.fieldSetContent6{
    border: 1px solid rgba(182, 182, 182, 0.815);
    border-radius: 10px;
    padding: 25px;
}
.classHolder6{
    padding: 1rem;
}
.custom-checkbox6 {
    transform: scale(1.7);  
    cursor: pointer;
}