.mainDiv3{
    width: 450px;
    overflow: hidden;
    border: 1px solid white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family:Arial, Helvetica, sans-serif;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.cardTop3{
    background-color:gold;
}
.cardImgHolder3 img{
    height: 100%;
    width: 100%;
}
.cardImgHolder3{
    width: 450px;
    padding:4px;
}
.classdetailsHeader3 h5{
    text-align: center;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 1.5rem;
}
.cardBottom3{
    padding: 1.5rem;
}
.cardBottom3 p {
    font-weight: bolder;
}
.CardIcons {
    margin-right: 1rem;
    font-size: x-large;
    font-weight: bolder;
    color: cornflowerblue;
}
.priceDetails3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}
.link3 a{
    color:  rgb(13, 100, 214);
    text-decoration: none;
    font-weight: bold;
}
.price3 h4{
    font-weight: 600;
    font-size: 1.2rem;
}
.notPrice3{
    font-size: 1rem;
    padding: 9px;
    font-weight: bold;
    color: rgb(156, 155, 155);
    text-decoration-thickness: 2px;
}
.buyNowbtn3{
    text-align: center;
    margin-top: 1rem;
}
.takeCourseBtn3{
    margin-top: 7px;
    background-color: rgb(101, 194, 9);
    padding: 0.5rem;
    color: white;
    border-radius: 30px;
    border: 1px solid rgb(101, 194, 9);
    width: 50%;
}
.squareIcon{
    margin-right: 5px;
    font-size: small;
}

.customDivider{
    border: none; 
    border-top: 1px solid #ccc; 
    margin: 0.5rem 0; 
    width: 100%;
}