 .progress-card {
    width: 300px;
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .progress-circle {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
  }
  
  .progress-ring {
    transition: stroke-dashoffset 0.5s ease-in-out;
  }
  
  .progress-text {
    font-size: 16px;
    font-weight: bold;
    fill: #333;
  }

  .class-title {
    font-size: 12px;
    color: gray;
    margin-top: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
  }
  
  .class-name {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin-top: 8px;
    text-align: center;
  }
  
  .instructor {
    font-size: 13px;
    color: gray;
    margin-top: 6px;
    text-align: center;
  }
  

  .progress-btn {
    display: inline-block;
    margin-top: 16px;
    padding: 10px 22px;
    background: white;
    border: 2px solid #f5a623;
    border-radius: 24px;
    color: #f5a623;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .progress-btn:hover {
    background: #f5a623;
    color: white;
  }
  